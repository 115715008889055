import React, { Component } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import BlocIcones from "../../../composants/boutique/BlocIcones"
import Image from "../../../composants/image/Image"
import LayoutBoutique from "../../../composants/layout/en/LayoutBoutique"
import SelectionFinition from "../../../composants/boutique/SelectionFinition"

import {
  objetEstDefini,
  obtenirImage,
  obtenirImages,
  obtenirPage,
} from "../../../js/client-es/utils"

import fragmentsImages from "../../../js/client-es/fragments/fragments-images"
import fragmentsPages from "../../../js/client-es/fragments/fragments-pages"
import ExplicationsFinitions from "../../../composants/boutique/en/ExplicationsFinitions"

export const requete = graphql`
  query requeteAchatTirageEN($codeImage: String!, $codePage: String!) {
    tirage: allImagesCompilationYaml(filter: { code: { eq: $codeImage } }) {
      ...FragmentImageTirage
    }
    pageTirage: allPagesCompilationYaml(filter: { code: { eq: $codePage } }) {
      ...FragmentPageYaml
    }
  }
`

export default class LayoutAchatTirage extends Component {
  constructor(props) {
    super(props)
    this.refSectionAchatTirage = React.createRef()
    this.refSectionExplications = null

    this.setRefExplications = element => {
      this.refSectionExplications = element
    }
  }

  componentDidMount() {
    history.replaceState({}, this.page.titre, this.page.url)
  }

  render() {
    const page = obtenirPage(this.props.data, "pageTirage")
    this.page = page
    const imageATirer = obtenirImage(
      obtenirImages(this.props.data, "tirage"),
      this.props.pageContext.codeImage
    )

    return (
      <LayoutBoutique page={page}>
        <section
          ref={this.refSectionAchatTirage}
          id="section-acheter-tirage"
          className="section-conteneur"
        >
          <div className="conteneur-cote-a-cote">
            <div>
              <Image
                image={imageATirer}
                langue={page.langue}
                afficherLegende={false}
              />
            </div>
            <div className="boutique-conteneur-bloc-achat">
              <h3>{imageATirer[page.langue].titre}</h3>
              <p>{imageATirer[page.langue].description}</p>
              <SelectionFinition
                image={imageATirer}
                langue={page.langue}
                callbackFocusSurExplications={this.focusSurExplications.bind(
                  this
                )}
              />
              <BlocIcones langue={page.langue} />
            </div>
          </div>
        </section>
        <div ref={this.setRefExplications} />
        <ExplicationsFinitions page={page} location={this.props.location} />
      </LayoutBoutique>
    )
  }

  focusSurExplications() {
    if (objetEstDefini(this.refSectionExplications))
      this.refSectionExplications.scrollIntoView({ behavior: "smooth" })
  }
}

LayoutAchatTirage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  pageContext: PropTypes.object,
}
