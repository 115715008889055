import React, { Component } from "react"
import PropTypes from "prop-types"
import BarreActions from "../../boutique/BarreActions"
import LayoutEN from "./LayoutEN"

import { chargerLibrairiesSnipcart } from "../../../js/client-es/snipcart"

export default class LayoutBoutique extends Component {
  constructor(props) {
    super(props)
    this.props = props
  }

  render() {
    return (
      <LayoutEN page={this.props.page}>
        <BarreActions langue={this.props.page.langue} />
        {this.props.children}
      </LayoutEN>
    )
  }

  componentDidMount() {
    chargerLibrairiesSnipcart()
  }
}

LayoutBoutique.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  page: PropTypes.object,
}
