import React from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"

import Image from "../../image/Image"

import { obtenirImage, obtenirImages } from "../../../js/client-es/utils"
import fragmentsImages from "../../../js/client-es/fragments/fragments-images"
import fragmentsPages from "../../../js/client-es/fragments/fragments-pages"

export const requete = graphql`
  {
    imagesPaysageTresPetit: allImagesCompilationYaml(
      filter: { code: { in: ["boutiqueDetailCaisseAmericaine"] } }
    ) {
      ...FragmentImagePaysageTresPetit
    }
    imagesPortraitPetit: allImagesCompilationYaml(
      filter: {
        code: {
          in: [
            "boutiqueDibond"
            "boutiqueDetailDibond"
            "boutiqueCaisseAmericaine"
          ]
        }
      }
    ) {
      ...FragmentImagePortraitPetit
    }
  }
`

export default function ExplicationsFinitions({ page }) {
  const resultatsRequete = useStaticQuery(requete)
  let images = obtenirImages(
    resultatsRequete,
    "imagesPaysageTresPetit",
    "imagesPortraitPetit"
  )

  return (
    <article>
      <section
        id="section-finitions"
        className="section-conteneur"
        style={{ marginTop: "50px" }}
      >
        <h2 className="section-titre">Finishes</h2>
        <h3>Print only</h3>
        <p>
          First price of the prints offered for sale, you will receive a rolled
          print. It will be necessary to have it framed or laminated on a rigid
          support. After your purchase, you will receive by email a numbered
          certificate of authenticity signed by the author.
        </p>
        <h3>Laminating on aluminum-Dibond </h3>
        <p>
          The print is made on Fine Art Baryta exhibition paper with
          incomparable texture and very deep blacks. The print is laminated on a
          2 to 3 mm aluminum-Dibond plate to guarantee its rigidity, which
          allows to hook it on a wall.
        </p>
        <div className="conteneur-cote-a-cote">
          <Image
            image={obtenirImage(images, "boutiqueDibond")}
            langue={page.langue}
          />
          <Image
            image={obtenirImage(images, "boutiqueDetailDibond")}
            langue={page.langue}
          />
        </div>
        <p>After your purchase, you will receive by post: </p>
        <ul>
          <li>An identification hologram to stick on the back of the print.</li>
          <li>
            A certificate of authenticity numbered and signed by the hand of the
            author.
          </li>
        </ul>

        <h3>Framing in shadow box</h3>
        <p>
          The print is made on Fine Art Baryté exhibition paper with
          incomparable texture and very deep blacks. The draw is laminated on an
          aluminum plate and it is placed in a shadow box frame. There is no
          glass, the print is highlighted and seems to float in its framing.
          This is the most prestigious finish offered in this shop.
        </p>
        <div className="conteneur-cote-a-cote">
          <Image
            image={obtenirImage(images, "boutiqueCaisseAmericaine")}
            langue={page.langue}
          />
          <Image
            image={obtenirImage(images, "boutiqueDetailCaisseAmericaine")}
            langue={page.langue}
          />
        </div>
        <p>After your purchase, you will receive by post: </p>
        <ul>
          <li>An identification hologram to stick on the back of the print.</li>
          <li>
            A certificate of authenticity numbered and signed by the hand of the
            author.
          </li>
        </ul>
      </section>
      <section className="section-conteneur" style={{ marginTop: "50px" }}>
        <h2 className="section-titre">Papers and inks</h2>
        <h3>Inks</h3>
        All prints are made using highly pigmented inks by a recognized French
        laboratory. Used with excellent papers, these inks show resistance over
        time that can last several generations.
        <h3>Papers</h3>
        <ul>
          <li>
            Prints only are printed on 310 g high density paper. Its satin
            appearance combines the shine of glossy paper and the reflective
            resistance of the matte paper.
          </li>
          <li>
            Fine Art prints laminated on aluminum-Dibond or framed in shadow box
            are printed on 340 g Fine Art Baryté paper. This high-end paper is
            used for exhibitions of many photographers. It allows rendering of
            very deep blacks. It also has a grain giving a real relief to
            astrophotographs.
          </li>
        </ul>
      </section>
    </article>
  )
}

ExplicationsFinitions.propTypes = {
  page: PropTypes.object,
  location: PropTypes.object,
}
